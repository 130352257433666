/* Colors */
$mainColor: rgba(37, 72, 127, 0.8);
$fa-font-path: "~font-awesome/fonts";

%icon {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::after {
        display: inline-block;
    }
}
