:root {
    font-family: $main-font;
    font-size: calc(1em + 0.15vw);
    line-height: 1.5;
    letter-spacing: 1px;
    box-sizing: border-box;
    *, *::before, *::after {
        box-sizing: inherit;
    }
}

a {
    &:hover {
        cursor: pointer;
    }
}

.osrm-directions-inputs,
.osrm-directions-errors,
.osrm-directions-routes,
.osrm-directions-instructions,
.osrm-directions-summary {
    font-size: 1em;
    h3 {
        margin-bottom: 0;
    }
}

.osrm-directions-inputs,
.osrm-directions-inputs *,
.osrm-directions-errors,
.osrm-directions-errors *,
.osrm-directions-routes,
.osrm-directions-routes *,
.osrm-directions-instructions,
.osrm-directions-instructions * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#progress {
    display: none;
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: 50%;
    width: 25vw;
    height: 3vh;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    padding: 2px;
}

#progress-bar {
    width: 0;
    height: 100%;
    background-color: #76a6fc;
    border-radius: 4px;
}

.map {
    background: #eee;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* tool colors and divs */
.fill-osrm {
    background-color: $main-color-transparent;
}

.fill-dark {
    background-color: #404040;
}

.dark {
    color: white;
}

/* Scale position */
.leaflet-control-scale {
    position: absolute;
    right: 20px;
    bottom: 10px;
    .leaflet-control-scale-line {
        background: none;
    }
}

/* Zoom/locate control position */
.leaflet-control-zoom,
.leaflet-control-locate {
    a {
        color: $main-color;
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.leaflet-control-locate {
    font-size: 1em;
}

.leaflet-touch {
    .leaflet-control-layers,
    .leaflet-control-zoom,
    .leaflet-control-locate {
        border-color: $main-color;;
        a {
            background-color: $main-color;
            color: #fff;
            border-color: #fff;
        }
    }

    .leaflet-control-layers-expanded {
        width: 180px;
        background-color: $main-color;
        padding: 0 0 6px 6px;
        color: #fff;
        .leaflet-control-layers-list {
            margin-top: 4px;
        }
    }

    .leaflet-retina .leaflet-control-layers-toggle {
        background-image: url('../images/layers.svg');
        background-size: 26px 26px;
    }

    .leaflet-control-layers-toggle {
        background-image: url('../images/layers.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 60%;
        width: 30px;
        height: 30px;
        float: right;
        display: block;
    }
}


/* Waypoint marker labels */
.leaflet-osrm-waypoint-label
{
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.leaflet-control-container {
    .leaflet-left {
        z-index: 1000;
    }
    .leaflet-right {
        z-index: 999;
    }
    .leaflet-right.leaflet-bottom {
        width: 100%;
    }
    .leaflet-right.leaflet-top {
        @media screen and (max-width:900px) {
            display: none;
        }
    }
    .leaflet-routing-container {
        display: flex;
        flex-flow: column;
        position: fixed;
        width: 33.3333%;
        max-width: 600px;
        min-width: 340px;
        border-width: 0 !important;
        border-radius: 0;
        z-index: 10;
        top: 0;
        left: 0;
        margin: 0 !important;
        background-color: $main-overlay;
        box-shadow: $main-shadow;
        @media screen and (max-width:900px) {
            max-width: 100%;
            width: 100%;
        }
        &.active.show-map {
            @media screen and (max-width:900px) {
                bottom: auto;
                .leaflet-routing-geocoders,
                .instructions-container {
                    display: none;
                }
            }
        }
        .leaflet-routing-alternatives-container {
            display: none;
        }
        &.active {
            bottom: 0;
            .leaflet-routing-alternatives-container {
                display: block;
            }
        }
    }
}

.leaflet-routing-geocoders div {
    padding: 4px 0 4px 0;
}

.leaflet-routing-geocoder {
    position: relative;
    background-color: #fff;
    border-bottom: 0 solid black;
    width: 100%;
}

.leaflet-container {
    font-family: $main-font;
    font-size: 1em;
    line-height: 1.2;
}

.leaflet-container button {
    margin: 0 10px 0 0;
    width: 40px;
    height: 36px;
    background-color: white;
    &:first-of-type {
        margin: 0;
    }
}

.leaflet-routing-geocoders button {
    margin: 0;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border: 0;
    margin: 0 0 0 5px;
    color: #fff;
    background: none;
    font-size: 1.4em;
}

.leaflet-routing-reverse-waypoints,
.leaflet-routing-add-waypoint {
    @extend %icon;
    font-size: 1em;
}

.leaflet-routing-add-waypoint {
    &::before {
        content: '\f067';
    }
}

.leaflet-routing-reverse-waypoints {
    position: relative;
    &::after {
        content: '\f0ec';
        transform: rotate(90deg);
        font-weight: bold;
    }
}

.leaflet-routing-remove-waypoint {
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    padding-left: 10px;
}

.leaflet-routing-remove-waypoint:after {
    position: absolute;
    display: block;
    width: 15px;
    height: 1px;
    z-index: 1;
    bottom: 0;
    margin: auto;
    font-size: 1em;
    font-weight: bold;
    content: '\00d7';
    text-align: center;
    cursor: pointer;
    color:  rgba(37, 72, 127, 0.6);
    padding-bottom: 16px;
    margin-top: -10px;
    margin-left: 0;
    line-height: 1;
}

.leaflet-routing-remove-waypoint {
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 10px;
}


.leaflet-routing-remove-waypoint:hover {
    color: black;
    transition: background-color 0.2s ease;
}

.leaflet-routing-alternatives-container {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: $container-padding;
    @media screen and (max-width:900px) {
        padding: 3vh;
    }
    @media (min-width:1800px) {
        padding: 36px;
    }
}

/* OSRM + Leaflet toolbar icons */

.leaflet-bottom .leaflet-control {
    margin-bottom: 0;
}

.leaflet-left .leaflet-control {
    margin-left: 10px;
    border-width: 0;
    border-color: rgba(0, 0, 0, 0.1);
}

.osrm-directions-icon {
    background-image: url('../images/osrm.toolbar.icons.svg');
    -webkit-background-size: 146px 24px;
    background-size: 146px 24px;
    margin: 0;
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
}

.osrm-add-icon  { background-position: -280px 0; }
.osrm-via-icon  { background-position: -300px 0; }

.osrm-editor-icon  { background-position: -48px 0; }
.osrm-josm-icon  { background-position: -72px 0; }
.osrm-debug-icon  { background-position: -96px 0; }
.osrm-mapillary-icon  { background-position: -120px 0; }
.osrm-flag-icon-en  { background-position: 0 0; }
.osrm-flag-icon-de  { background-position: -24px 0; }

.leaflet-osrm-tools-hide
{
    display: none;
}

/* OSRM geocoder directions inputs */
.osrm-directions-inputs {
    flex: 0 1 auto;
    background-color: $main-color;
    padding: $container-padding;
    padding-bottom: 10px;
    @media screen and (max-width:900px) {
        padding: 2em 1em 1em 2em;
    }
    @media (min-width:1800px) {
        padding: 36px;
        padding-bottom: 10px;
    }
    .close {
        float: right;
        margin: -0.5em -0.5em 0 0;
        color: #fff;
        background: none;
        border: none;
        &:hover {
            border: none;
            background: none;
            color: #fff;
        }
    }
    .leaflet-routing-add-waypoint {
        position: relative;
        left: -0.25em;
        width: auto;
        padding: 0;
        font-family: $main-font;
        font-size: 1em;
        span {
            display: inline-block;
            vertical-align: middle;
        }
        &::before {
            content: '\f067';
            margin-right: 0.5em;
            font-family: 'FontAwesome';
            font-size: 1.4em;
            vertical-align: middle;
        }
    }
    .control-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-container {
            flex: 1;
        }
        .leaflet-routing-reverse-waypoints {
            margin-right: -1em;
        }
        .leaflet-routing-geocoder-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 10px 0;
            .leaflet-routing-geocoder {
                margin: 0 0 0 10px;
                input:focus {
                    outline-width: 0;
                }
            }
            &::before {
                content: '';
                font-size: 1.2em;
                font-weight: bold;
                height: 0.35em;
                width: 0.35em;
                border-radius: 50%;
                background-color: #fff;
            }
            &::after {
                display: none;
                content: '\22EE';
                position: absolute;
                margin: 0 0 0 -.35em;
                transform: translateY(-120%);
                font-size: 1.5em;
                opacity: 0.7;
            }
            &:first-of-type {
                &::before {
                    content: 'A';
                    height: auto;
                    margin: 0;
                    background: none;
                    position: relative;
                    left: -0.2em;
                }
                &::after {
                    display: none;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
                &::before {
                    content: 'B';
                    height: auto;
                    margin: 0;
                    background: none;
                    position: relative;
                    left: -0.2em;
                }
            }
        }
    }
}

.leaflet-routing-geocoders.osrm-directions-inputs .leaflet-routing-geocoder input {
    border: 0;
    height: 100%;
}

.osrm-directions-inputs input {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    width: 90%;
    background-color: #fff;
    color: #222;
    height: 30px;
    padding: 10px 10px 10px 20px;
}

.osrm-directions-route-summary {
    display: none;
}

.osrm-directions-route-active .osrm-directions-route-summary {
    display: block;
}

/* directions icons */
.osrm-directions-instructions .osrm-directions-icon {
    position: absolute;
    left: 10px;
    top: 25px;
    margin: auto;
}

.osrm-continue-icon         { background-position: 0 0; }
.osrm-sharp-right-icon      { background-position: -20px 0; }
.osrm-turn-right-icon       { background-position: -40px 0; }
.osrm-bear-right-icon       { background-position: -60px 0; }
.osrm-u-turn-icon           { background-position: -80px 0; }
.osrm-sharp-left-icon       { background-position: -100px 0; }
.osrm-turn-left-icon        { background-position: -120px 0; }
.osrm-bear-left-icon        { background-position: -140px 0; }
.osrm-depart-icon           {
    background-position: -160px -1;
    background-color: #87de9f;
}
.osrm-enter-roundabout-icon { background-position: -180px 0; }
.osrm-arrive-icon           {
    background-position: -200px 0;
    background-color: #db5c0a;
}
.osrm-close-icon            { background-position: -220px 0; }
.osrm-reverse-icon          { background-position: -240px 0; }
.osrm-error-icon            { background-position: -260px 0; }

.osrm-marker-drag-icon {
    display: block;
    background-color: #444;
    border-radius: 50%;
}

.osrm-marker-drag-icon-step {
    background-color: #3bb2d0;
}

.leaflet-osrm-tools-container
{
    display: none;
    width: auto;
    text-align: justify;
    padding: 2px 4px;
}

.leaflet-osrm-tools-container div
{
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    padding: 2px 0;
}
.leaflet-osrm-tools-container span:hover
{
    background-color: rgba(255, 255, 255, 0.2);
}

/* Instructions */
.osrm-directions-steps {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.osrm-directions-step {
    position: relative;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    padding: 20px 20px 20px 40px;
    font-size: 1em;
}

.osrm-directions-step-distance {
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding: 5px 10px;
    font-size: 0.9em;
    left: 30px;
    bottom: -15px;
}

.osrm-directions-step:hover {
    color: white;
}

.osrm-directions-step:after {
    content: '';
    position: absolute;
    top: 50px;
    bottom: -20px;
    border-left: 2px dotted rgba(255, 255, 255, 0.2);
    left: 20px;
}

.osrm-directions-step:last-child:after,
.osrm-directions-step:last-child .osrm-directions-step-distance {
    display: none;
}

/* Directions Summary */
.osrm-directions-summary {
    color: $font-color;
}

.osrm-directions-instructions {
    display: flex;
    flex-direction: column;
    justify-content: flext-start;
    .instructions-container {
        color: $font-color;
        .instructions-step {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: $main-border;
            &:last-child {
                border: none;
            }
            .icon {
                width: 30px;
            }
            .distance {
                font-size: 0.75em;
                color: #999;
                padding-right: 10px;
                white-space: nowrap;
            }
            .text {
                padding: 1.5vw;
                width: 100%;
                @media screen and (max-width:900px) {
                    padding: 2.3vh;
                }
                @media (min-width:1900px) {
                    padding: 28.5px;
                }
            }
        }
    }
}

.leaflet-routing-alt.osrm-directions-instructions.leaflet-routing-alt-minimized {
    display: none;
}

.leaflet-routing-alt-minimized .osrm-directions-summary {
    color: $font-color;
    padding-top: 80px;
}

.leaflet-routing-alt table {
    color: $font-color;
    font-weight: 400;
    td {
        padding: 5px 0;
        &:last-child {
            white-space: nowrap;
        }
    }
}

.leaflet-routing-alt-minimized table td,
.leaflet-routing-alt-minimized table {
    color: $font-color;
    margin-top: 20px;
    margin-top: 20px;
    padding: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 1em;
}

.osrm-directions-summary {
    h2 {
        font-size: 1.3em;
        font-weight: bold;
        margin: 0;
    }
    h3 {
        font-weight: normal;
        margin: 5px 0 0 0;
    }
}

.leaflet-osrm-geocoder-label
{
    width: 20px;
    height: 20px;
    font-family: Arial;
    font-variant: bold;
    font-size: 1em;
    color: white;
}

.leaflet-routing-alt table td:hover {
    cursor: pointer;
    background-color: white;
}

.leaflet-routing-alt table tr:hover {
    background-color: white;
}

td.distance {
    min-width: 60px;
}

.leaflet-routing-icon {
    background-image: url('../images/osrm.directions.icons.color.svg');
    -webkit-background-size: 455px 20px;
    background-size: 455px 20px;
    background-repeat: no-repeat;
    margin: 0;
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
}

.leaflet-routing-icon.lanes.invalid {
    filter: invert(50%);
}

.leaflet-routing-alt-minimized .leaflet-routing-icon {
    background-image: url('../images/osrm.directions.icons.color.svg');
}

/* Icons */

.leaflet-routing-icon-arrive,
.leaflet-routing-icon-depart {
    background-size: cover;
    width: 20px;
    height: 56px;
    position: relative;
    top: 13px;
}

.leaflet-routing-icon-depart {
    background-image: url('../images/marker-start-icon-2x.png');
}

.leaflet-routing-icon-arrive {
    background-image: url('../images/marker-end-icon-2x.png');
}

.leaflet-routing-icon-via { background-position: -278px 0; }

.leaflet-routing-icon-continue { background: url('../images/icons/continue.svg'); }
.leaflet-routing-icon-end { background: url('../images/icons/arrive.svg'); }

.leaflet-routing-icon-sharp-right { background: url('../images/icons/turn_sharp_right.svg'); }
.leaflet-routing-icon-turn-right { background: url('../images/icons/turn_right.svg'); }
.leaflet-routing-icon-bear-right { background: url('../images/icons/turn_slight_right.svg'); }
.leaflet-routing-icon-ramp-right { background: url('../images/icons/on_ramp_right.svg'); }
.leaflet-routing-icon-merge-right { background: url('../images/icons/merge_right.svg'); }

.leaflet-routing-icon-sharp-left { background: url('../images/icons/turn_sharp_left.svg'); }
.leaflet-routing-icon-turn-left { background: url('../images/icons/turn_left.svg'); }
.leaflet-routing-icon-bear-left { background: url('../images/icons/turn_slight_left.svg'); }
.leaflet-routing-icon-ramp-left { background: url('../images/icons/on_ramp_left.svg'); }
.leaflet-routing-icon-merge-left { background: url('../images/icons/merge_left.svg'); }

.leaflet-routing-icon-enter-roundabout { background: url('../images/icons/roundabout.svg'); }
.leaflet-routing-icon-u-turn { background: url('../images/icons/uturn.svg'); }
.leaflet-routing-icon-fork { background: url('../images/icons/fork.svg'); }



/*  FORM Labels */
.osrm-form-label {
    cursor: default;
}

.osrm-directions-origin .osrm-form-label {
    background-color: #ee8a65;
}
.osrm-directions-via {
    background-color: white;
    position: relative;
}
.osrm-directions-via .osrm-form-label {
    background-color: #ccc;
}

.osrm-directions-via input {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Leaflet marker labels */
.leaflet-label {
    background: rgb(235, 235, 235);
    background: rgba(235, 235, 235, 0.81);
    background-clip: padding-box;
    border-color: #777;
    border-color: rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-style: solid;
    border-width: 4px;
    color: #111;
    display: block;
    font: 12px/20px 'Open Sans', sans-serif;
    font-weight: bold;
    padding: 1px 6px;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    z-index: 6;
}

.leaflet-label.leaflet-clickable {
    cursor: pointer;
    pointer-events: auto;
}

.leaflet-label:before,
.leaflet-label:after {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: none;
    position: absolute;
    top: 5px;
}

.leaflet-label:before {
    border-right: 6px solid black;
    border-right-color: inherit;
    left: -10px;
}

.leaflet-label:after {
    border-left: 6px solid black;
    border-left-color: inherit;
    right: -10px;
}

.leaflet-label-right:before,
.leaflet-label-left:after {
    content: '';
}


/* Small Screen Layout
------------------------------------------------------- */
@media only screen and (max-width:880px) {
    .osrm-directions-summary {
        margin: 0;
    }
    .osrm-directions-summary h2 {
        font-size: 1.2em;
        font-weight: bold;
    }
    .osrm-directions-summary h3 {
        font-size: 1.1em;
    }
    .leaflet-bottom .leaflet-control {
        margin-bottom: 0;
    }
    .leaflet-control-zoom {
        display: hidden;
    }
}

/* geocoding autocomplete styling */

.leaflet-routing-geocoder-result {
    position: absolute;
    z-index: 1000;
    overflow: hidden;
    max-height: 0;
    color: #222;
    transition: all 0.2s ease;
    box-shadow: 0 15px 15px 0px rgba(0,0,0,.15);
    table {
        width: 100%;
        background-color: white;
        border-collapse: collapse;
        border-radius: 0 0 4px 4px;
        cursor: pointer;
    }
    tr {
        border-bottom: $main-border;
        &:last-child {
            border: none;
        }
    }
    td {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 1em;
        span {
            display: inline-block;
            margin: 0 0 0 0.4em;
            &:first-child {
                margin: 0;
            }
            &.place {
                &::after {
                    content: ',';
                }
            }
            &.text {
                display: none;
            }
        }
        div.region {
            font-size: 0.85em;
            color: #777;
        }
    }
}

.leaflet-routing-geocoder-result-open {
    overflow: visible;
    max-height: 800px;
}

.leaflet-routing-geocoder-result tr:hover {
    background-color: hsl(200, 90, 90);
    &:last-child td {
        border-radius: 0 0 4px 4px;
    }
}

.leaflet-routing-geocoder-selected {
    background-color: hsl(200, 90, 90);
    font-weight: bold;
}

.leaflet-routing-geocoder-no-results {
    font-style: italic;
    color: #888;
}

.leaflet-routing-collapse-btn {
    display: none;
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: $main-color;
    width: 36px;
    height: 36px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1em;
    justify-content:center;
    align-items: center;
    color: white;
}

.leaflet-routing-collapse-btn:after {
    content: '\203a';
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn:after {
    content: '\2039';
}

