$main-color: rgb(180, 40, 46);
$main-color-transparent: rgba(180, 40, 46, 0.8);
$secondary-color: rgb(37, 72, 127);

$main-font: 'Roboto', sans-serif;
$font-color: #333;

$main-border: 1px solid hsla(0,0,0,0.1);
$main-shadow: 3px 3px 25px 10px rgba(0,0,0,0.15);
$main-overlay: hsla(0,0%,100%,.95);

$container-padding: 2vw;
