.leaflet-control {
    &.route-profile-control {
        margin: 0;
        width: 67%;
        min-width: calc(100% - 600px);
        height: 15vh;
        min-height: 200px;
        @media screen and (max-width:900px) {
            min-height: 20vh;
            width: 100%;
        }
        .info {
            display: none;
            position: absolute;
            z-index: 9999;
            top: 1em;
            left: 1em;
            margin: 0;
            padding: 1em;
            list-style: none;
            border: $main-border;
            background: #fff;
            box-shadow: 3px 3px 10px 0px rgba(0,0,0,.2);
            font-size: 0.85em;
            font-weight: bold;
            color: $font-color;
            span {
                font-weight: normal;
                &.effort {
                    display: none;
                }
            }
            li {
                margin: 0;
            }
            &.active {
                display: block;
            }
            &.hover {
                opacity: 0.4;
            }
        }
    }
    .route-profile-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        box-shadow: 0 -3px 25px 10px rgba(0,0,0,.15);
        background: $main-overlay;
        transition: transform ease-in-out 0.25s;
        transform: translateY(100%);
        &.active {
            transform: translateY(0);
        }
        .chart-container {
            flex: 1;
            width: 100%;
        }
        .chart-elevation,
        .chart-headwind {
            text-align: left;
            display: none;
            &.active {
                display: block;
            }
            .max,
            .min {
                display: none;
                position: relative;
                width: 100%;
                font-size: 0.8em;
                text-align: right;
                white-space: nowrap;
                &:after {
                    content: '-';
                    color: white;
                    display: inline-block;
                    width: 10px;
                    margin: 0 0 0 5px;
                    position: relative;
                }
            }
            .max {
                top: 20px;
                &:after {
                    top: -10px;
                    border-bottom: 2px $secondary-color solid;
                }
            }
            .min {
                bottom: 20px;
                &:after {
                    bottom: -10px;
                    border-top: 2px $secondary-color solid;
                }
            }
            .axis {
                g {
                    @media screen and (max-width:900px) {
                        display: none;
                        &:last-of-type,
                        &:first-of-type {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .toggle {
            transform: translate(-50%, -80%);
            align-self: flex-end;
            color: $main-color;
            font-size: 1.3em;
            cursor: pointer;
        }
        svg {
            display: block;
            &:hover {
                cursor: pointer;
            }
            .domain {
                display: none;
            }
            .overlay {
                fill: none;
                pointer-events: all;
            }
            .grid {
                opacity: 0.05;
                path {
                    fill: none;
                }
                line {
                    stroke-width: 1;
                }
            }
            .profile-line-master {
                visibility: hidden;
            }
            path {
                stroke: none;
                fill: #80c38b;
            }
            text {
                fill: #000;
                font-size: 1.2em;
                font-family: $main-font;
            }
            rect {
                fill: none;
            }
        }
        .chart-elevation {
            .axis {
                .tick {
                    &:first-of-type {
                        display: none;
                    }
                }
            }
        }
        .chart-headwind {
            svg {
                transform: translateY(-10px);
                .profile-area {
                    path:last-of-type {
                        fill: rgb(225, 94, 94);
                        clip-path: url(#clip);
                    }
                }
            }
        }
    }
    .route-profile-tabs {
        display: inline-block;
        visibility: hidden;
        list-style: none;
        padding: 0;
        margin: 0;
        transform: translate(50%, -100%);
        border-radius: 5px 5px 0 0;
        background-color: $main-color;
        font-size: 0.8em;
        &.active {
            visibility: visible;
        }
        li {
            padding: 0 10px;
            cursor: pointer;
            border: 0;
            border-radius: 5px;
            padding: .5em .75em;
            display: inline-block;
            color: #fff;
            font-weight: bold;
            text-transform: capitalize;
            &:first-child {
                border-radius: 5px 0 0 0;
            }
            &:last-child {
                border-radius: 0 5px 0 0;
            }
            &.active {
                cursor: default;
                color: $font-color;
                background-color: #fff;
                border-radius: 5px 5px 0 0;
                transform: translateZ(0);
            }
        }
    }
}

/* Route Summary */

.route-profile-summary {
    margin-bottom: 20px;
    @media screen and (max-width:900px) {
        margin-bottom: 5px;
    }
    ul {
        display: inline-block;
        padding: 0;
        margin: 3px 0 0;
    }
    li {
        list-style: none;
        display: inline-block;
    }
    button {
        display: none;
        float: right;
        transform: translateY(-1em);
        width: auto;
        padding: 0 1em;
        border-radius: 2em;
        border: none;
        background: $main-color;
        color: #fff;
        @media screen and (max-width:900px) {
            display: inline-block;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .elevation-summary {
        margin-left: -3px;
        text-align: left;
        li {
            &:first-child {
                &::before {
                    @extend %icon;
                    margin: 0 5px 0 0;
                    content: '\f175';
                }
            }

            &:last-child {
                margin-left: 10px;
                &::before {
                    @extend %icon;
                    margin: 0 5px 0 0;
                    content: '\f176';
                }
            }
        }
    }
}

/* Route/profile markers */

circle {
    fill: white;
    stroke: $secondary-color;
    stroke-width: 2px;
}

/* Loading animations */

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.loader {
    color: #ccc;
    font-size: 0.5em;
    margin: 30px auto;
    margin-top: 0;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

