.leaflet-cluster-anim {
    .leaflet-marker-icon,
    .leaflet-marker-shadow {
	   transition: transform 0.3s ease-out, opacity 0.3s ease-in;
    }
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
