.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
    div {
        font: 12px 'Helvetica Neue', sans-serif;
        text-align: center;
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;
        border-radius: 15px;
    }

    span {
        line-height: 30px;
    }
}

.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
    div {
        background-color: rgba(110, 204, 57, 0.6);
    }

    .leaflet-oldie & {
        background-color: rgb(181, 226, 140);
        div {
            background-color: rgb(110, 204, 57);
        }
    }
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
    div {
        background-color: rgba(240, 194, 12, 0.6);
    }

    .leaflet-oldie & {
        background-color: rgb(241, 211, 87);
        div {
            background-color: rgb(240, 194, 12);
        }
    }
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
    div {
        background-color: rgba(241, 128, 23, 0.6);
    }

    .leaflet-oldie & {
        background-color: rgb(253, 156, 115);
        div {
            background-color: rgb(241, 128, 23);
        }
    }
}
